import { cn } from '@frontend/utils/tailwindUtil';
import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'lime'
    | 'purple'
    | 'subscribe'
    | 'twitter';
  shadow?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const Button = ({
  variant = 'primary',
  shadow = true,
  size = 'md',
  className,
  children,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={cn(
        'rounded-lg font-medium transition-all duration-100 text-sm',
        // Size variants
        {
          'px-3 py-2': size === 'sm',
          'px-4 py-3': size === 'md',
          'px-8 py-3': size === 'lg',
        },
        'active:translate-x-[3px] active:translate-y-[3px]',
        // Color variants
        {
          'bg-[var(--foreground)] text-white border': variant === 'primary',
          'bg-[var(--foreground)] text-white': variant === 'secondary',
          'bg-[var(--lime)] text-foreground': variant === 'lime',
          'bg-[var(--purple)] text-white': variant === 'purple',
          'bg-white text-[var(--purple)] border border-[var(--purple)]':
            variant === 'subscribe',
          'bg-black text-white border border-white': variant === 'twitter',
        },
        // Shadow variant (shadow prop = true by default)
        shadow && {
          'shadow-[8px_6px_0_0_var(--purple)] active:shadow-none':
            variant === 'primary' || variant === 'subscribe',
          'shadow-md active:shadow-none':
            variant !== 'primary' &&
            variant !== 'subscribe' &&
            variant !== 'twitter',
          'shadow-[6px_6px_0_0_white] active:shadow-none':
            variant === 'twitter',
        },
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};
