/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
'use client';

import { useEffect, useRef, useState } from 'react';
import { Button } from './ui/Button';
import Logo from './ui/Logo';
import MobileMenu from './ui/mobile-menu';

export const navigation = [
  // { name: 'Business', href: '#business' },
  // { name: 'About', href: '#about' },
  // { name: 'Blog', href: '#blog' },
  // { name: 'Docs', href: '#docs' },
];

export default function Navbar() {
  const [, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white lg:bg-transparent">
      <nav
        aria-label="Global"
        className="flex items-center justify-between p-6 lg:px-8 max-w-6xl mx-auto"
      >
        <div className="flex items-center gap-x-12">
          <a href="/go" className="rounded-full">
            <span className="sr-only">Yodl</span>
            <Logo />
          </a>
          {/* <div className="hidden lg:flex lg:gap-x-12 bg-[#F1F1F2]/50 backdrop-blur px-8 py-3 rounded-xl [&:has(a:hover)_a:not(:hover)]:text-neutral-400">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm/6 text-[--background-accent] transition-all duration-300"
              >
                {item.name}
              </a>
            ))}
          </div> */}
        </div>
        <div className="flex lg:hidden">
          <MobileMenu />
        </div>
        <div className="hidden lg:flex">
          <a href="/go" className="text-sm/6 font-semibold text-gray-900">
            <Button>Launch App</Button>
          </a>
        </div>
      </nav>
    </header>
  );
}
