import Image from 'next/image';
import logo from '@frontend/public/assets/images/Yodl-Logo.png';

export default function Logo() {
  return (
    <div className="relative w-32 h-8">
      <Image src={logo} alt="Logo" fill className="object-contain" />
    </div>
  );
}
